<template>
  <div class="dropdown">
    <div
      v-click-outside="hideMenu"
      class="menu-dropdown"
      :class="params.value.options && 'cursor-pointer'"
      :style="{
        backgroundColor: statusColor,
        backgroundImage: !params.value.options && 'none',
      }"
      @click="showMenu = true"
    >
      <p class="m-0">
        {{
          isStatusValid
            ? $t(`borrow_return_statuses.${params.value.name}`)
            : "-"
        }}
      </p>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { BorrowReturnStatuses } from "@/constants/borrow-return/BorrowReturnStatuses";
import { mapGetters } from "vuex";

export default {
  directives: {
    ClickOutside,
  },
  data() {
    return {
      status: {
        name: "",
        options: [],
      },
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters(["isBorrowReturnAdmin"]),
    isStatusValid() {
      return BorrowReturnStatuses[this.status.name] !== undefined;
    },
    statusColor() {
      switch (this.status.name) {
        case BorrowReturnStatuses.TO_PREPARE:
          return "#FFF5C0";
        case BorrowReturnStatuses.TO_SEND:
          if (this.isBorrowReturnAdmin) {
            return "#FFF5C0";
          }
          return "#C0F0FF";
        case BorrowReturnStatuses.PREPARED:
          if (this.isBorrowReturnAdmin) {
            return "#C0F0FF";
          }
          return "#FFF5C0";
        case BorrowReturnStatuses.USING:
          return "#C0F0FF";
        case BorrowReturnStatuses.TO_RETURN:
          return "#FFE7D1";
        case BorrowReturnStatuses.RETURNED:
          return "#CDFFC0";
        case BorrowReturnStatuses.RETURNED_DAMAGED:
          return "#E0CACA";
        case BorrowReturnStatuses.OVERDUE:
          return "#FFC0C0";
        case BorrowReturnStatuses.RETURNED_OVERDUE:
          return "#FFE2C0";
        case BorrowReturnStatuses.RETURNED_DAMAGED_OVERDUE:
          return "#E0E2E7";
        case BorrowReturnStatuses.LOST:
          return "#E0CACA";
        case BorrowReturnStatuses.NOT_AVAILABLE_PREPARE:
        case BorrowReturnStatuses.NOT_AVAILABLE_SEND:
          return "#FFD1D1";
        case BorrowReturnStatuses.CANCEL:
          return "#E0E2E7";
        default:
          return null;
      }
    },
    statusText() {
      switch (this.status.name) {
        case BorrowReturnStatuses.TO_PREPARE:
          if (this.isBorrowReturnAdmin) {
            return this.$t("borrow.need_prepare_status");
          }
          return this.$t("borrow.preparing_status");
        case BorrowReturnStatuses.PREPARED:
          if (this.isBorrowReturnAdmin) {
            return this.$t("borrow.prepared_status");
          }
          return this.$t("borrow.preparing_status");
        case BorrowReturnStatuses.TO_SEND:
          if (this.isBorrowReturnAdmin) {
            return this.$t("borrow.need_send_status");
          }
          return this.$t("borrow.need_recieve_status");
        case BorrowReturnStatuses.USING:
          return this.$t("borrow.inuse_status");
        case BorrowReturnStatuses.TO_RETURN:
          return this.$t("borrow.need_return_status");
        case BorrowReturnStatuses.RETURNED:
          return this.$t("borrow.normal_returned_status");
        case BorrowReturnStatuses.RETURNED_DAMAGED:
          return this.$t("borrow.broken_returned_status");
        case BorrowReturnStatuses.CANCEL:
          return this.$t("borrow.cancelled_status");
        case BorrowReturnStatuses.OVERDUE:
          return this.$t("borrow.overdue_status");
        case BorrowReturnStatuses.RETURNED_OVERDUE:
          return this.$t("borrow.overdue_status");
        case BorrowReturnStatuses.RETURNED_DAMAGED_OVERDUE:
          return this.$t("borrow.overdue_broken_status");
        case BorrowReturnStatuses.NOT_AVAILABLE_PREPARE:
          return this.$t("borrow.not_ready_status");
        case BorrowReturnStatuses.NOT_AVAILABLE_SEND:
          return this.$t("borrow.not_ready_status");
        case BorrowReturnStatuses.LOST:
          return this.$t("borrow.lost_status");
        default:
          return "-";
      }
    },
  },
  methods: {
    getValue() {
      return this.params.value;
    },
    hideMenu() {
      this.showMenu = false;
    },
  },
  created() {
    const { name, options } = this.params.value;
    this.status = {
      name,
      options,
    };
  },
};
</script>

<style lang="scss" scoped>
.status-card {
  padding: 0 10px;
  border: 1px solid #e0e2e7;
  border-radius: 8px;
  p {
    margin: 0;
  }
}

.dropdown {
  position: relative;
  z-index: 1000;
  display: inline-block;
  width: 160px;
}

.menu-dropdown {
  background-image: url("../../../../assets/down-arrow-black.svg");
  background-repeat: no-repeat;
  background-position: 97% 50%;
  padding: 0 40px 0 10px;
  border: 1px solid #e0e2e7;
  border-radius: 8px;
}

.dropdown-content {
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  padding: 0.5rem;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  z-index: 10;
  .option {
    line-height: normal;
    &:hover {
      color: #007afe;
    }
  }
}
</style>
